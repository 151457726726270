<template>
  <div>
    <h3 class="mb-2">{{ heading }}</h3>
    <div class="d-flex" style="gap: 10px">
      <v-text-field
        label="Postal Code"
        v-model="address.postalCode"
        variant="solo"
        class="rounded-sm"
        autocomplete="postal-code"
        bg-color="grey-darken-4"
      ></v-text-field>
      <v-text-field
        label="City"
        v-model="address.city"
        variant="solo"
        autocomplete="address-level2"
        class="rounded-sm"
        bg-color="grey-darken-4"
      ></v-text-field>
    </div>
    <v-text-field
      label="Address"
      v-model="address.address"
      variant="solo"
      autocomplete="street-address"
      class="rounded-sm"
      bg-color="grey-darken-4"
    ></v-text-field>
    <v-autocomplete
      label="Country"
      variant="solo"
      class="rounded-sm"
      v-model="address.country"
      item-title="name"
      item-value="code"
      :items="countries"
      autocomplete="country"
      bg-color="grey-darken-4"
    ></v-autocomplete>
    <v-divider class="mb-5" />
  </div>
</template>

<script>
import { countries } from "iso-3166-1-alpha-2";
import { getAddressOfUser } from "@/services/user.service";
export default {
  name: "EditAddress",
  props: {
    prefill: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: "Address",
    },
  },
  data() {
    return {
      address: {},
      countriesList: countries,
      countries: [],
    };
  },
  watch: {
    address: {
      handler: function (val) {
        this.$emit("updateAddress", val);
      },
      deep: true,
    },
  },
  created() {
    this.countries = this.mapCountries();
    if (this.prefill) {
      this.prefillAdress();
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    async prefillAdress() {
      if (this.currentUser)
        this.address = await getAddressOfUser(this.currentUser.id);
    },
    mapCountries() {
      return Object.keys(this.countriesList).map((code) => {
        return {
          name: this.countriesList[code],
          code: code,
        };
      });
    },
  },
};
</script>

<style scoped></style>
