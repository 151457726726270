<template>
  <v-list class="bg-grey-darken-4">
    <div class="d-flex justify-center">
      <v-text-field
        solo
        class="input-border mx-5"
        placeholder="Search for user"
        prepend-inner-icon="mdi-magnify"
        v-model="search"
      ></v-text-field>
      <v-select
        class="custom-border-radius"
        solo
        small-chips
        :items="roles"
        v-model="selectedRoles"
        placeholder="Select Roles..."
        multiple
      ></v-select>
    </div>
    <div style="width: 100%" class="d-flex justify-end px-5">
      <v-btn :loading="loading" x-small @click="getAllUsers"
        >Refresh <v-icon x-small>mdi-refresh</v-icon></v-btn
      >
    </div>
    <v-dialog v-if="hasRoleAdmin" v-model="showCreateUserDialog">
      <template #activator="{ props }">
        <v-list-item v-bind="props" link class="offwhite mx-5 my-2">
          <template #prepend>
            <v-icon>mdi-plus</v-icon>
          </template>
          <template #title>
            <span class="font-weight-bold mr-5" style="font-size: 1.3rem"
              >Add new User</span
            >
          </template>
        </v-list-item>
      </template>
      <v-sheet>
        <CreateUser
          @cancel="() => (this.showCreateUserDialog = false)"
          @save="() => (this.showCreateUserDialog = false)"
          role="ROLE_USER"
        />
      </v-sheet>
    </v-dialog>
    <AdminUserListItem
      v-for="user in searchedUsers"
      :key="user.id"
      @deleteUser="deleteUser"
      :user="user"
      @updateUser="updateUser"
    />
  </v-list>
</template>

<script>
import { getUserListForAdmin } from "@/services/user.service";
import AdminUserListItem from "@/components/management/user/AdminUserListItem";
import CreateUser from "@/components/artist/CreateUser";

export default {
  name: "AdminUserList",
  components: { CreateUser, AdminUserListItem },
  data() {
    return {
      loading: false,
      users: null,
      selectedRoles: [],
      search: "",
      showCreateUserDialog: false,
    };
  },
  computed: {
    roles() {
      if (!this.users) return [];
      let allRoles = [];
      this.users.forEach((user) => {
        allRoles = allRoles.concat(user.roles);
      });
      return [...new Set(allRoles)];
    },
    searchedUsers() {
      let result;
      if (this.users) {
        result = this.users.filter(
          (x) =>
            x.name?.toLowerCase().includes(this.search.toLowerCase()) ||
            x.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
            x.email.toLowerCase().includes(this.search.toLowerCase()) ||
            x.roles.findIndex((role) =>
              role.toLowerCase().includes(this.search.toLowerCase())
            ) !== -1
        );
        if (this.selectedRoles.length > 0)
          result = result.filter(
            (x) =>
              x.roles.findIndex((role) => this.selectedRoles.includes(role)) !=
              -1
          );
        return result.sort((a, b) => {
          if (!a.name || !b.name) return 0;
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      } else return [];
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    hasRoleAdmin() {
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    deleteUser(id) {
      if (this.users) {
        this.users.splice(
          this.users.findIndex((x) => x.id === id),
          1
        );
      }
    },
    updateUser(user) {
      this.users[this.users.findIndex((x) => x.id === user.id)] = user;
    },
    async getAllUsers() {
      this.loading = true;
      this.users = await getUserListForAdmin();
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
