<template>
  <v-list-item
    :class="{ selected: selected }"
    @click="selectMethod"
    class="payment-method bg-grey-darken-4 py-3 custom-border-radius"
  >
    <v-list-item-title class="d-flex justify-start align-center">
      <img :src="paymentMethod.image" :alt="paymentMethod.name" />
      <h3 class="ml-2">{{ paymentMethod.name }}</h3>
      <p
        style="
          position: absolute;
          top: 85%;
          transform: translateY(-50%);
          right: 0.5em;
          font-size: 0.5em;
        "
        class="pa-2"
      >
        Fee {{ paymentMethod.costs.amount }} {{ paymentMethod.costs.currency }}
      </p>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    selected: Boolean,
    paymentMethod: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedPaymentMethod: null,
    };
  },
  methods: {
    selectMethod() {
      this.$emit("select", this.paymentMethod.uid);
    },
  },
};
</script>

<style scoped>
.payment-method {
  margin-bottom: 20px;
}

.payment-method img {
  max-width: 100px;
}
.selected {
  border: 3px solid black !important;
}
</style>
