<template>
  <div>
    <h2 class="text-center">scan your Ticket Barcode</h2>
    <div class="mx-auto ma-5" style="width:300px;height:300px;position: relative">
      <qrcode-stream @detect="onDetect" @error="onError"/>
      <v-overlay contained :model-value="loading" opacity="0.5">
        <v-progress-circular
            indeterminate
            size="300px"
        ></v-progress-circular>
      </v-overlay>

    </div>
    <div class="mx-auto pa-5">
      <h2 class="text-center">or type it in manually</h2>
      <div class="ma-auto" style="position: relative">
      <v-otp-input
          v-model="ticketBarcode"
          style="max-width: 500px"
          class="mx-auto"
          length="12"
          :loading="loading"
          @finish="onCodeDetected"
      >

      </v-otp-input>
        <v-overlay absolute :value="loading">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-overlay>

      </div>
    </div>
    </div>
</template>

<script>
import {  QrcodeStream} from 'vue-qrcode-reader'
import {notificationsStore} from "@/store/notifications.module";
export default {
  name: "QRCodeReader",
  components: {
    QrcodeStream,
  },
  data(){
    return{
      ticketBarcode:null,
    }
  },
  props:{
    loading:Boolean,
  },
  methods:{
    async onDetect(detectedCodes) {
      let code = await detectedCodes;
      this.ticketBarcode=code;
      this.$emit("code",code);
    },
    onCodeDetected (rsp) {
      this.$emit("code",rsp);

    },
    onError(err){
      let error={};
      error.value = `[${err.name}]: `

      if (err.name === 'NotAllowedError') {
      error.value += 'you need to grant camera access permission'
    } else if (err.name === 'NotFoundError') {
    error.value += 'no camera on this device'
  } else if (err.name === 'NotSupportedError') {
    error.value += 'secure context required (HTTPS, localhost)'
  } else if (err.name === 'NotReadableError') {
    error.value += 'is the camera already in use?'
  } else if (err.name === 'OverconstrainedError') {
    error.value += 'installed cameras are not suitable'
  } else if (err.name === 'StreamApiNotSupportedError') {
    error.value += 'Stream API is not supported in this browser'
  } else if (err.name === 'InsecureContextError') {
    error.value += 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
  } else {
    error.value += err.message
  }
      console.log("Error",error)

      this.$store.dispatch("notificationsStore/pushError",error.value);
},
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height }
        } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },
  }
}
</script>

<style scoped>

</style>