<template>
  <v-container class="text-center">
    <div :color="color" class="mb-5">
      <h1>Order <span v-text="orderState"></span></h1>
      <p v-if="orderState === 'completed'">
        Thank you for your purchase! Your order has been completed successfully.
      </p>
      <p v-else-if="orderState === 'pending'">
        Your order is currently pending. Please wait for further updates.
      </p>
      <p v-else>There was an issue with your order. Please contact support.</p>
    </div>
    <div>
      <v-skeleton-loader
        v-if="loading"
        color="transparent"
        type="list-item-three-line"
        :loading="loading"
        height="200"
        width="400"
      />
      <div v-else>
        <OrderItem v-if="order" :order="order" />
        <div v-else>You received an email with your order details</div>
      </div>
    </div>
  </v-container>
</template>

<script>
import OrderList from "@/components/order/OrderList";
import { getOrderById } from "@/services/order.service";
import OrderItem from "@/components/order/OrderItem.vue";
export default {
  name: "AfterPayment",
  components: { OrderItem, OrderList },
  data() {
    return {
      order: null,
      loading: false,
    };
  },
  computed: {
    color() {
      if (this.orderState === "completed") return "success";
      else if (this.orderState === "pending") return "warning";
      else return "grey";
    },
    orderId() {
      return this.$route.query.order_id;
    },
    email() {
      return this.$route.query.email;
    },
    orderState() {
      return this.$route.query.order_state;
    },
  },
  created() {
    this.getOrder();
  },
  methods: {
    async getOrder() {
      if (!this.orderId) return;
      this.loading = true;
      let email = this.email;
      let response = await getOrderById(this.orderId, email);
      if (response.status === 200) this.order = response.data;
      else this.order = null;
      this.loading = false;
    },
    toggleOrderList() {
      this.showOrderList = !this.showOrderList;
    },
  },
};
</script>

<style scoped>
.v-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
</style>
