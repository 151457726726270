<template>
  <v-list height="410px" class="bg-primary" style="overflow-y: scroll">
    <v-row no-gutters>
      <v-col
        v-for="method of methods"
        :key="method.uid"
        cols="12"
        md="6"
        class="px-2"
      >
        <PaymentMethod
          @select="setSelectedPaymentMethod"
          :selected="selectedPaymentMethod === method.uid"
          :payment-method="method"
        />
      </v-col>
    </v-row>
  </v-list>
</template>

<script>
import PaymentMethod from "@/components/order/payment/PaymentMethod";
export default {
  name: "SelectPaymentMethod",
  components: { PaymentMethod },
  methods: {
    setSelectedPaymentMethod(value) {
      this.selectedPaymentMethod = value;
      this.$emit("updatePaymentMethod", value);
    },
  },
  props: {
    methods: Array,
  },
  data() {
    return {
      selectedPaymentMethod: null,
    };
  },
};
</script>

<style scoped></style>
