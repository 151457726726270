<template>
<v-form class="pa-5">

  <label class="custom-label">
    Eventname
  <v-text-field class="input-border" solo v-model="event.title"/>
  </label>
  <label class="custom-label">
    Description
  <v-textarea class="input-border" solo v-model="event.description"/>
  </label>
  <label class="custom-label">
    Genre
    <div class="d-flex justify-start flex-wrap">
      <GenrePicker :genre-to-edit="eventToEdit?.genres" @update="updateGenre"/>
    </div>
  </label>
  <label class="custom-label">
    Ticketlink
  <v-text-field class="input-border" solo v-model="event.ticketLink"/>
  </label>
  <label class="custom-label">
    Start
        <v-text-field
            type="datetime-local"
            class="input-border"
            v-model="event.dateTime"
            prepend-icon="mdi-calendar"
        ></v-text-field>

  </label>
  <label class="custom-label">
    End
    <v-text-field
        type="datetime-local"
        class="input-border"
        v-model="event.end"
        prepend-icon="mdi-calendar"
    ></v-text-field>

  </label>
  <label class="custom-label">
    Venue
    <v-combobox hide-no-data  :items="allVenues" class="input-border" solo v-model="event.venue"/>
  </label>
<label class="custom-label">
  Artists
  <v-autocomplete
      class="input-border"
      v-model="event.artists"
      :items="artists"
      item-value="id"
      item-text="name"
      item-title="name"
      chips
      small-chips
      label="Select Artists"
      multiple
      solo
  ></v-autocomplete>
</label>
  <v-switch hint="Is the Event a Festival or Conference?" inset v-model="event.conference">
    <template v-slot:label>
      <div>
      <div v-if="event.conference">Conference</div>
      <div v-else>Festival</div>
      </div>
    </template>
  </v-switch>
  <label v-if="event.conference" class="custom-label">
    Conference Categories
    <v-select :menu-props="{ top: true, offsetY: true }"  placeholder="Select Categories..." solo multiple small-chips return-object v-model="event.conferenceTopics" :items="conferenceTopics"/>
  </label>
  <label v-if="event.conference" class="custom-label">
    Conference Type
    <v-select placeholder="Select Topic..." solo small-chips return-object :items="conferenceTypes" v-model="event.conferenceType"></v-select>
  </label>
  <div style="max-width: 70%" class="d-flex mx-auto justify-space-between">
    <v-btn text :disabled="loading" @click="cancel">Cancel</v-btn><v-btn v-if="!event.id" @click="save" :loading="loading" outlined>Save</v-btn><v-btn v-else :loading="loading" @click="update" outlined>Update</v-btn>
  </div>
</v-form>
</template>

<script>
import Event from "@/models/event"
import {getAllArtists} from "@/services/artist.service";
import GenrePicker from "@/components/artist/GenrePicker";
import {createEvent, updateEvent} from "@/services/event.service";
import {getAllVenueNames} from "@/services/venue.service";
export default {
  name: "CreateEvent",
  components: {GenrePicker},
  props:{
    eventToEdit:Event
  },
  data(){
    return{
      conferenceTopics:[
        "Strategies",
        "Opportunities",
        "Business",
        "Responsibilities",
        "Networking",
        "Artists",
        "Newcomer",
        "Creation",
        "Management",
        "Labels & Publishing",
        "Events",
        "Marketing",
        "Streaming",
        "Legal",
        "Media",
        "Technology",
        "AI",
        "Data",
        "Startups",
        "Sustainability",
        "Social Change",
        "Inclusivity",
        "Diversity",
        "Culture",
        "(Mental) Health",
        "Global Scenes",
        "Local Scenes",
        "Visual",
        "Gear",
        "DJ/Producer",
        "Journalism",
        "Mix & Master",
        "Curation",
        "Remix",
        "Music Professional",
        "Artist Course",
        "Producer Course",
        "Fashion"
      ],
      conferenceTypes:[
        "Masterclass",
        "Keynote",
        "Q&A",
        "Networking",
        "Contests/Pitches",
        "Live Performance",
        "Interview",
        "Paneltalk",
        "Workshop",
        "Exhibition"
      ],
      allVenues:[],
      loading:false,
      artists:[],
      timeDialog:false,
      dateDialog:false,
      endDialog:false,
      dateDialogEnd:false,
      end:null,
      date:null,
      time:null,
      event:new Event(),
      dateEnd:null,
     }

  },
  watch:{
  },
  created() {
    this.getAllVenues();
    if(this.eventToEdit){
      let moment = require("moment");
      moment.locale('de');
      this.eventToEdit.dateTime = moment(this.eventToEdit.dateTime).format('YYYY-MM-DDTHH:mm:ss');
      this.eventToEdit.end = moment(this.eventToEdit.end).format('YYYY-MM-DDTHH:mm:ss');
      this.event=JSON.parse(JSON.stringify(this.eventToEdit));
    }
    this.getArtists();
  },
  methods:{
    async getAllVenues() {
      this.allVenues = await getAllVenueNames();
    },
    cancel(){
      this.$emit("close");
    },
    localDateToZonedDateTime(dateTime) {
      let moment = require("moment-timezone");
      return moment.tz(dateTime, 'Europe/Berlin').format('YYYY-MM-DDTHH:mm:ssZ');
    },
    async save() {
      this.loading=true;
      this.event.dateTime = this.localDateToZonedDateTime(this.event.dateTime);
      this.event.end= this.localDateToZonedDateTime(this.event.end);

      let response = await createEvent(this.event);
      this.loading=false;
      if(response.status==200) this.$emit("success",response.data);
      this.$emit("close");

    },
    async update(){
      this.loading=true;
      this.event.dateTime = this.localDateToZonedDateTime(this.event.dateTime);
      this.event.end= this.localDateToZonedDateTime(this.event.end);
      let response = await updateEvent(this.event);
      this.loading=false;
      if(response.status==200) this.$emit("success",response.data);
    },
    updateGenre(genre){
      console.log("genres",genre)
      this.event.genres=genre;
    },
    async getArtists() {
      this.artists = await getAllArtists();
    },
    combineDateTime(dateString, timeString) {
  const [year, month, day] = dateString.split('-').map(Number);
  const [hours, minutes] = timeString.split(':').map(Number);

  // Create a new Date object with the date part
  const combinedDateTime = new Date(year, month - 1, day);

  // Set the time part of the date object
  combinedDateTime.setHours(hours);
  combinedDateTime.setMinutes(minutes);

  return combinedDateTime;
}

}
}
</script>

<style scoped>

</style>