import authHeader from "@/services/auth-header";
import axios from "axios";
import host from "./host";
import store from "@/store";
import Notification from "@/models/notification";
import {router} from "@/router";

const API_URL = host+'/api/';
//const API_URL = 'https://deep-diverse.com:8443/api/';
class ApiService {
    async get(url) {
        return await this.betterGet(url);
    }
    getUnauthorized(url) {
        return axios.get(API_URL + url);
    }postUnauthorized(url,data) {
        return axios.post(API_URL + url,data)
    }

    async post(url, data) {
        // eslint-disable-next-line no-console
        return await this.betterPost(url, data);
    }
    getWithoutApi(url) {
        return axios.get(host + "/" + url, {headers: authHeader()});
    }
    postWithoutApi(url, data) {
        // eslint-disable-next-line no-console
        return axios.post(host + "/" + url, data, {headers: authHeader()})
    }

    async betterPost(url, data) {
        try {
            return await axios.post(API_URL + url, data, {headers: authHeader()});
        } catch (error) {
            this.handleErrorE(error);
            throw error;
        }
    }
    async betterGet(url) {
        try {
            return await axios.get(API_URL + url, {headers: authHeader()});
        } catch (error) {
            this.handleErrorE(error);
            throw error;
        }
    }
    handleErrorE(error){
        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch("auth/logout");
                router.push("/login");
            } else if (error.response.data.message) {
                store.dispatch("loading/error");
                store.dispatch("notificationsStore/add", new Notification("error", error.response.data.message, false));
            }
        }
    }
}
export default new ApiService();
