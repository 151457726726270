<template>
  <v-sheet rounded="lg" class="pa-5" elevation="2" color="grey-darken-4">
    <v-expansion-panels color="grey-darken-4" elevation="0">
      <v-expansion-panel elevation="0">
        <template #title>
          <div class="d-flex flex-column flex-grow-1">
            <v-list-item width="100%">
              <template #title>
                <h3 v-text="'Order #' + order.uid.substr(0, 10) + '...'"></h3>
              </template>
              <template #subtitle>
                <h5 v-text="'Status: ' + order.status"></h5>
              </template>
              <template #append>
                <v-btn
                  :href="order.downloadLink"
                  v-if="completed"
                  variant="outlined"
                  target="_blank"
                  ><v-icon small>mdi-download</v-icon>Download</v-btn
                >
              </template>
            </v-list-item>
            <v-alert
              v-if="order.status === 'pending' && order.paymentLink"
              variant="outlined"
              elevation="1"
              color="accentYellow"
              class="rounded align-center"
              icon="mdi-cash-clock"
            >
              <template #text>
                <h3>You have an outstanding payment for this order</h3>
              </template>
              <template #append>
                <v-btn
                  variant="text"
                  class="align-self-center"
                  style="z-index: 1003"
                  target="_blank"
                  :href="order.paymentLink"
                  >Pay now</v-btn
                >
              </template>
            </v-alert>
          </div>
        </template>
        <v-expansion-panel-text class="bg-grey-darken-4">
          <div class="bg-grey-darken-4">
            <SingleTicket
              v-for="ticket of order.tickets"
              :key="ticket.uid"
              :ticket="ticket"
            />

            <v-divider class="my-2"> </v-divider>
            <div class="d-flex justify-space-between">
              <span>Total</span><span v-text="totalPrice"></span>
            </div>
            <div class="d-flex justify-space-between">
              <span>Service Fee</span><span v-text="serviceFee"></span>
            </div>
            <div class="d-flex justify-space-between">
              <span>Grand Total</span><span v-text="grandTotal"></span>
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-sheet>
</template>

<script>
import SingleTicket from "@/components/ticket/SingleTicket";
import PayOrder from "@/components/order/payment/PayOrder";
import App from "@/App.vue";
export default {
  name: "OrderItem",
  components: { App, PayOrder, SingleTicket },
  props: {
    order: Object,
  },
  data() {
    return {
      showPayment: false,
    };
  },
  computed: {
    totalPrice() {
      let Euro = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });
      return Euro.format(this.order.totalPrice.amount);
    },

    completed() {
      return this.order.status === "completed";
    },
    serviceFee() {
      let Euro = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });
      return Euro.format(this.order.serviceFee.amount);
    },
    grandTotal() {
      let Euro = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });
      return Euro.format(this.order.grandTotal.amount);
    },
  },
};
</script>

<style scoped></style>
