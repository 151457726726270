<template>
  <v-container class="mx-auto">
    <v-list class="mx-auto bg-transparent">
      <!-- <ArtistListItemPublic
        v-for="artist of artistsSorted"
        :key="artist.id"
        :artist="artist"
      /> -->
      <div class="wrapper mb-8 mb-md-3" v-if="!loading">
        <UserSheetWall
          v-for="artist of artistsSorted"
          :key="artist.id"
          :index="artist.id"
          :hover="false"
          :user="artist"
        />
      </div>
    </v-list>
  </v-container>
</template>

<script>
import UserSheetWall from "@/views/Lobby/UserSheetWall";

export default {
  name: "ArtistListPublic",
  components: { UserSheetWall },
  props: {
    artists: Array,
  },
  created() {
    console.log("Artists", this.artists);
  },
  computed: {
    artistsSorted() {
      return [...this.artists].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

@media screen and (max-width: 600px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 0 10px;
  }

  .wrapper > *:nth-child(even) {
    transform: translateY(30px);
  }
}
.wrapper > div {
  width: 100%;
  height: 100%;
  min-height: 200px;
}
</style>
