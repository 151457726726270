<template>
  <div>
    <v-list class="bg-grey-darken-4 pa-5">
      <v-text-field
        @keyup="debounceInitEvents"
        v-model="query"
        label="Search"
        variant="solo"
        hide-details
        bg-color="primary"
      ></v-text-field>
      <v-select
        class="my-5"
        @update:modelValue="debounceInitEvents"
        v-model="years"
        :items="availableYears"
        label="Filter by year"
        multiple
        hide-details
      ></v-select>
      <div>
        <span class="mx-1" style="font-size: 0.9rem"
          >{{ page.totalElements }} Events found</span
        >
      </div>
      <v-list class="bg-grey-darken-4">
        <v-dialog v-model="showCreateDialog" v-if="isAdmin">
          <template #activator="{ props }">
            <v-list-item v-bind="props" link class="offwhite mx-5 my-2">
              <template #prepend>
                <v-icon>mdi-plus</v-icon>
              </template>
              <template #title>
                <span class="font-weight-bold mr-5" style="font-size: 1.3rem"
                  >Add new Event</span
                >
              </template>
            </v-list-item>
          </template>
          <v-sheet>
            <CreateEvent @close="closeDialog" @success="addEvent" />
          </v-sheet>
        </v-dialog>
        <div style="width: 100%" class="d-flex justify-end px-5">
          <v-btn :loading="loading" x-small @click="getMyEvents"
            >Refresh <v-icon x-small>mdi-refresh</v-icon></v-btn
          >
        </div>
      </v-list>
      <AdminEventListItem
        @delete="deleteEvent"
        v-for="event of events"
        :key="event.id"
        :event="event"
        @updateEvent="updateEvent"
      />
    </v-list>
  </div>
</template>

<script>
import { getAllMyEventsPage } from "@/services/event.service";
import AdminEventListItem from "@/components/management/events/AdminEventListItem";
import CreateEvent from "@/components/event/CreateEvent";
import { Page } from "@/models/page";
import { debounce } from "lodash";

export default {
  name: "AdminEventList",
  components: { CreateEvent, AdminEventListItem },
  data() {
    return {
      showCreateDialog: false,
      loading: false,
      page: new Page(),
      availableYears: [2024, 2025],
      query: null,
      years: [2025],
    };
  },
  created() {
    this.debounceEvents = debounce(this.initEvents, 200);
    this.getMyEvents();
  },
  computed: {
    events() {
      return this.page.content;
    },
    isAdmin() {
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    addEvent(){
     this.initEvents();
    },
    debounceInitEvents() {
      this.debounceEvents();
    },
    closeDialog() {
      this.showCreateDialog = false;
    },
    deleteEvent(id) {
      this.events.splice(
        this.events.findIndex((x) => x.event.id === id),
        1
      );
    },
    updateEvent(event) {
      let index = this.events.findIndex((x) => x.event.id === event.id);
      this.events[index].event = event;
    },
    async initEvents() {
      this.loading = true;
      console.log("initEvents");
      this.page = await getAllMyEventsPage(0, {
        query: this.query,
        years: this.years,
      });
      this.loading = false;
    },
    async getMyEvents() {
      this.loading = true;
      this.page = await getAllMyEventsPage(this.page.number + 1, {
        query: this.query,
        years: this.years,
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
