<template>
  <v-form ref="form" theme="dark" v-model="valid" autocomplete="on">
    <h2 class="mb-5">Ticket Owner</h2>
    <div class="d-flex" style="gap: 10px">
      <v-text-field
        autofocus
        class="ticket-form rounded-sm"
        bg-color="grey-darken-4"
        variant="solo"
        label="Enter First Name"
        v-model="user.firstName"
        :rules="nameRules"
        required
        autocomplete="given-name"
      />
      <v-text-field
        class="ticket-form rounded-sm"
        bg-color="grey-darken-4"
        variant="solo"
        autofocus
        label="Enter Last Name"
        v-model="user.lastName"
        :rules="nameRules"
        required
        autocomplete="family-name"
      />
    </div>
    <v-text-field
      class="ticket-form rounded-sm"
      bg-color="grey-darken-4"
      variant="solo"
      type="email"
      label="Enter your email address"
      v-model="user.email"
      :rules="emailRules"
      required
      autocomplete="email"
    />
    <div class="d-flex" style="gap: 10px">
      <v-select
        class="ticket-form rounded-sm"
        bg-color="grey-darken-4"
        variant="solo"
        :items="[
          { name: 'Female', value: '2' },
          { name: 'Male', value: '1' },
          { name: 'Diverse', value: '9' },
        ]"
        v-model="user.gender"
        item-title="name"
        item-value="value"
        label="Gender"
        :rules="[(v) => !!v || 'Gender is required']"
        required
        autocomplete="sex"
      />
      <v-dialog
        ref="dialog"
        v-model="show"
        :return-value.sync="user.date_of_birth"
        width="290px"
      >
        <template v-slot:activator="{ props }">
          <v-text-field
            bg-color="grey-darken-4"
            class="ticket-form"
            variant="solo"
            v-model="formattedUserBirth"
            label="Date of Birth"
            prepend-inner-icon="mdi-calendar"
            readonly
            :rules="[(v) => !!v || 'Date of birth is required']"
            required
            v-bind="props"
            autocomplete="bday"
          ></v-text-field>
        </template>
        <v-date-picker
          :max="new Date()"
          v-model="user.date_of_birth"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              $refs.dialog.save(null);
              show = false;
            "
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(user.date_of_birth)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <EditAddress
      heading="Your address"
      prefill
      @updateAddress="updateAddress"
      required
    />
    <v-row>
      <v-col class="shrink v-col-1 flex-shrink-1 d-flex align-center">
        <v-checkbox
          v-model="termsAccepted"
          class="ma-0 pa-0"
          :rules="[(v) => !!v || 'You must accept the terms']"
          required
          hide-details
        >
        </v-checkbox>
      </v-col>
      <v-col class="d-flex align-center">
        <span>
          <span>I accept the</span>
          <a class="mx-1" href="https://berlin-dance-music-event.com/agb/"
            ><span> terms and conditions </span></a
          >
          and
          <a
            class="mx-1"
            href="https://berlin-dance-music-event.com/privacy-policy/"
            >data protection
          </a>
        </span>
      </v-col>
    </v-row>
    <slot name="submit"> </slot>
  </v-form>
</template>

<script>
import EditAddress from "@/components/address/EditAddress.vue";
import { TicketConsumer } from "@/components/cart/checkout/ticketConsomer";

export default {
  name: "TicketConsumerForm",
  components: { EditAddress },
  props: {},
  data() {
    return {
      nameRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length >= 2) || "Name must be at least 2 characters",
        (v) => (v && v.length <= 50) || "Name must be less than 50 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      user: new TicketConsumer(),
      valid: false,
      value: true,
      show: false,
      rules: {
        required: (value) =>
          !!value || this.$t("registration.passwordRequired"),
      },
      termsAccepted: false,
    };
  },
  watch: {
    user: {
      handler: function (val) {
        const requiredFields = [
          "firstName",
          "lastName",
          "email",
          "gender",
          "date_of_birth",
        ];
        const isUserValid = requiredFields.every((field) => !!val[field]);

        this.valid = isUserValid && this.termsAccepted && !!this.user.address;
        this.emitValidation();
      },
      deep: true,
    },
    termsAccepted() {
      this.emitValidation();
    },
    valid() {
      this.emitValidation();
    },
  },
  methods: {
    updateAddress(address) {
      if (!address) {
        this.valid = false;
        return;
      }
      const requiredFields = ["street", "city", "postalCode", "country"];
      const isAddressValid = requiredFields.every((field) => !!address[field]);

      if (!isAddressValid) {
        this.valid = false;
      }
      this.user.address = address;
    },
    emitValidation() {
      this.$emit("formValidation", {
        isValid: this.valid && this.termsAccepted,
        user: this.user,
      });
    },
  },
  created() {
    if (this.loggedIn) {
      this.user.firstName = this.$store.state.auth.user?.firstName;
      this.user.lastName = this.$store.state.auth.user?.lastName;
      this.user.email = this.$store.state.auth.user?.email;
    }
  },
  computed: {
    formattedUserBirth() {
      return this.user.date_of_birth
        ? new Date(this.user.date_of_birth).toLocaleDateString()
        : "";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>

<style scoped>
.ticket-form {
  border-radius: 10px;
}
</style>
