<template>
  <v-card
    :elevation="$vuetify.display.smAndDown ? 0 : 2"
    rounded="lg"
    class="my-5"
    color="grey-darken-4"
    flat
  >
    <v-list-item>
      <template #prepend>
        <v-avatar tile rounded size="45">
          <UserAvatar :user="content.autor" />
        </v-avatar>
      </template>
      <template #title>
        <div v-text="content.autor.name"></div>
      </template>
      <template #subtitle> </template>
      <template #append>
        <v-menu
          v-if="
            content.autor.id == currentUser.id ||
            currentUser.roles.includes('ROLE_ADMIN')
          "
        >
          <template v-slot:activator="{ props }">
            <v-btn variant="icon" v-bind="props">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded class="pa-0 ma-0 bg-grey-darken-3">
            <v-list-item @click="deleteFeedItem">
              <v-btn
                prepend-icon="mdi-trash-can-outline"
                variant="text"
                color="error"
                >Delete Post</v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-list-item>
    <v-img
      v-if="content.file"
      :src="'data:image/png;base64,' + content.file"
      height="400px"
      width="400px"
    ></v-img>
    <div class="d-flex px-2 py-1 justify-start">
      <v-btn v-if="!currentUserLikes" @click="like" variant="icon"
        ><v-icon>mdi-heart-outline</v-icon></v-btn
      >
      <v-btn v-else variant="icon" color="red" @click="dislike"
        ><v-icon>mdi-heart</v-icon></v-btn
      >
      <v-bottom-sheet v-model="showComments" scrollable height="70vh">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="icon"
            ><v-icon>mdi-message-outline</v-icon></v-btn
          >
        </template>
        <v-card :class="$vuetify.display.smAndDown ? 'rounded-t-xl' : ''">
          <v-btn variant="plain" @click="showComments = false"
            ><div
              class="rounded-t"
              style="
                height: 4px;
                width: 100px;
                background-color: black;
                border-radius: 3px;
              "
            ></div
          ></v-btn>
          <v-card-text>
            <v-list>
              <v-list-item>
                <template #prepend>
                  <v-avatar>
                    <v-img
                      :src="
                        content.autor.profilePic
                          ? 'data:image/png;base64,' +
                            content.autor.profilePic.file
                          : require('@/assets/Placeholder-Profile-819x1024.webp')
                      "
                    >
                    </v-img>
                  </v-avatar>
                </template>
                <template #title>
                  <p style="font-size: 0.7rem">
                    <span v-text="content.autor.name"></span
                    ><span class="font-weight-bold ml-2">{{
                      firstCommentDate
                    }}</span>
                  </p>
                </template>

                <p v-text="content.text"></p>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <CommentsList
              @addComment="addComment"
              @deleteComment="deleteComment"
              :comments="content.comments"
            />
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </div>
    <div class="px-4 py-1">{{ content.likes.length }} Likes</div>
    <div class="px-4 py-1">
      <b v-text="content.autor.name"></b> <span v-text="content.text"></span>
    </div>
    <small class="px-4 py-1" v-text="date"></small>
  </v-card>
</template>

<script>
import FeedItem from "@/models/feedItem";
import UserAvatar from "@/components/UserAvatar";
import {
  addComment,
  deleteComment,
  likeOrDislikeFeed,
  deleteFeedItem,
} from "@/services/lobby.service";
import CommentsList from "@/components/feed/CommentsList";

export default {
  name: "FeedItem",
  components: { CommentsList, UserAvatar },
  props: {
    content: FeedItem,
  },
  data() {
    return {
      showComments: false,
    };
  },
  emits: ["deleteItem"],
  methods: {
    async deleteFeedItem() {
      try {
        await deleteFeedItem(this.content.id);
        this.$emit("deleteItem", this.content.id);
      } catch (error) {
        console.log("Error", error);
      }
    },
    async addComment(comment) {
      let response = await addComment(this.content.id, comment);
      console.log("Repsonse", response);
      if (response) {
        let item = this.content;
        item.comments.push(response);
        this.$emit("updateItem", item);
      }
    },
    async deleteComment(commentId) {
      let response = await deleteComment(commentId, this.content.id);
      if (response) {
        let item = this.content;
        item.comments = item.comments.filter((x) => x.id != commentId);
        this.$emit("updateItem", item);
      }
    },
    like() {
      let success = likeOrDislikeFeed(true, this.content.id);
      let item = this.content;
      if (success) {
        item.likes.push(this.currentUser.id);
        this.$emit("updateItem", item);
      }
    },
    dislike() {
      let success = likeOrDislikeFeed(false, this.content.id);
      let item = this.content;
      if (success) {
        item.likes = item.likes.filter((x) => x != this.currentUser.id);
        this.$emit("updateItem", item);
      }
    },
  },
  created() {
    console.log(this.content);
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentUserLikes() {
      if (this.currentUser)
        return this.content.likes.includes(this.currentUser.id);
      else return false;
    },
    firstCommentDate() {
      let moment = require("moment");
      return moment(this.content.creationDate).fromNow();
    },
    date() {
      let moment = require("moment");
      return moment(this.content.creationDate).format("LL");
    },
  },
};
</script>

<style scoped>
.el {
  box-shadow: none;
}
@media only screen and (min-width: 768px) {
  .el {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
