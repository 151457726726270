<template>
  <div class="transparent">
    <ViewHeadline headlineText="Program" />
    <v-container class="py-5">
      <v-tabs
        id="filterTabs"
        v-model="whatToShow"
        grow
        class="mt-n3 mb-5"
        background-color="transparent"
      >
        <v-tab value="events" class="filter-chip" size="x-large">
          Events
        </v-tab>
        <v-tab value="artists" class="filter-chip" size="x-large">
          <span v-if="conferenceOrFestival.includes('festival')">Artists</span>
          <span v-if="conferenceOrFestival?.length > 1" class="mx-1"> & </span>
          <span v-if="conferenceOrFestival.includes('conference')"
            >Speaker</span
          >
        </v-tab>
        <v-tab value="venues" class="filter-chip" size="x-large">
          Venues
        </v-tab>
      </v-tabs>
      <v-row class="">
        <v-col cols="10">
          <v-chip-group
            color="white"
            filter
            multiple
            mandatory
            v-model="conferenceOrFestival"
          >
            <v-chip
              rounded="lg"
              variant="tonal"
              value="conference"
              class="filter-chip"
              size="x-large"
              >Conference</v-chip
            >
            <v-chip
              rounded="lg"
              variant="tonal"
              value="festival"
              class="filter-chip"
              size="x-large"
              >Festival</v-chip
            >
          </v-chip-group>
        </v-col>

        <v-col cols="2" class="d-flex align-center justify-end">
          <v-btn
            v-if="$vuetify.display.mdAndUp"
            variant="tonal"
            rounded="lg"
            color="black"
            :class="{ 'filter-chip-active': selectedGenres?.length > 0 }"
            @click="selectGenre = !selectGenre"
            size="large"
            prepend-icon="mdi-tune-vertical"
          >
            <span>Filter</span>
          </v-btn>
          <v-btn
            v-else
            variant="tonal"
            density="comfortable"
            color="black"
            :class="{ 'filter-chip-active': selectedGenres?.length > 0 }"
            @click="selectGenre = !selectGenre"
            size="large"
            icon="mdi-tune-vertical"
          >
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-window class="bg-primary" v-model="whatToShow">
      <v-window-item value="events">
        <v-list v-if="!loading" class="bg-primary events">
          <div v-if="days?.length <= 0">
            <h3 class="text-center">Nothing matches your search :(</h3>
          </div>

          <div v-else style="position: relative">
            <v-container
              class=""
              v-for="day of filteredEventsGroupedByDay"
              :key="day.day"
            >
              <h3 class="text-uppercase text-color-accent mt-2">
                {{ dateToday(day.day) }}
              </h3>

              <div class="py-2" v-for="event of day.events" :key="event.id">
                <EventItem
                  :event="event.event"
                  :artists="event.artists"
                  @addToFavourites="(id) => event.event.savedByIds.push(id)"
                  @removeFromFavourites="
                    (id) =>
                      event.event.savedByIds.splice(
                        event.event.savedByIds.indexOf((x) => (x.id = id)),
                        1
                      )
                  "
                />
              </div>
            </v-container>
          </div>
        </v-list>

        <!-- Skeleton loader während des Ladens -->

        <v-container v-else>
          <div v-for="n in 5" :key="n">
            <v-card
              flat
              class="my-2 pa-8 bg-grey-darken-4 custom-border-radius"
            >
              <v-row>
                <v-col cols="2" class="hidden-sm-and-down">
                  <v-skeleton-loader
                    class="rounded-lg"
                    color="transparent"
                    width="80%"
                    height="80%"
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="10" class="pt-1">
                  <v-skeleton-loader
                    class="mt-2"
                    type="heading"
                    color="transparent"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="mt-2"
                    type="text"
                    color="transparent"
                  ></v-skeleton-loader>
                  <div
                    class="d-flex ga-1 justify-space-between flex-wrap ml-3 mt-5"
                  >
                    <div class="d-flex ga-2 flex-wrap">
                      <v-skeleton-loader
                        class="mr-2"
                        width="100"
                        type="chip"
                        color="transparent"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                        class="mr-2"
                        width="100"
                        type="chip"
                        color="transparent"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                        width="100"
                        type="chip"
                        color="transparent"
                      ></v-skeleton-loader>
                    </div>
                    <v-skeleton-loader
                      width="100"
                      type="chip"
                      color="transparent"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-container>
      </v-window-item>
      <v-window-item value="artists">
        <ArtistListPublic :artists="filteredArtists" />
      </v-window-item>
      <!--Artists-->

      <v-window-item value="venues">
        <AllVenues />
      </v-window-item>
    </v-window>
    <v-dialog max-width="400px" v-model="selectGenre">
      <v-card class="pa-4" color="primary">
        <h2 class="text-center mb-5">Filter</h2>
        <div>
          <h3>Days</h3>
          <v-chip-group filter column multiple v-model="selectedDays">
            <v-chip
              variant="flat"
              class="filter-chip"
              color="white"
              v-for="day of allDays"
              :value="day"
              :key="day"
              >{{ weekday(day) }}</v-chip
            >
          </v-chip-group>
        </div>
        <div>
          <h3>Genres</h3>
          <v-chip-group filter column multiple v-model="selectedGenres">
            <v-chip
              variant="flat"
              class="filter-chip"
              color="white"
              v-for="genre of allGenres"
              :value="genre.id"
              :key="genre.id"
              >{{ genre.name }}</v-chip
            >
          </v-chip-group>
        </div>

        <div>
          <h3>Conference Type</h3>
          <v-chip-group filter column multiple v-model="selectedCTypes">
            <v-chip
              variant="flat"
              class="filter-chip"
              color="white"
              v-for="type of allConferenceTypes"
              :value="type"
              :key="type"
              >{{ type }}</v-chip
            >
          </v-chip-group>
        </div>
        <div>
          <h3>Conference Topic</h3>
          <v-chip-group filter column multiple v-model="selectedCTopics">
            <v-chip
              variant="flat"
              class="filter-chip"
              color="white"
              v-for="topic of allConferenceTopics"
              :value="topic"
              :key="topic"
              >{{ topic }}</v-chip
            >
          </v-chip-group>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ApiService from "../../services/api.service";
import EventItem from "@/views/Event/EventItem";
import {
  getAllDaysWithEvents,
  getAllEventsGroupedByDateOfCurrentYear,
  getEvents,
} from "@/services/event.service";
import ArtistListPublic from "@/components/artist/ArtistListPublic.vue";
import AllVenues from "@/components/venue/AllVenues";
import { getAllVisibleArtists } from "@/services/artist.service";
import ViewHeadline from "@/components/helpers/ViewHeadline.vue";
export default {
  name: "EventSite",
  components: { AllVenues, ArtistListPublic, EventItem, ViewHeadline },
  data() {
    return {
      days:[],
      selectedGenres: [],
      selectGenre: false,
      whatToShow: "events",
      events: [],
      loading: false,
      searchQuery: "",
      conferenceOrFestival: ["festival", "conference"],
      selectedVenues: [],
      selectedDays: [],
      allArtists: [],
      selectedCTopics: [],
      selectedCTypes: [],
    };
  },
  async created() {
    this.loading = true;
    try {
      await this.getAllDaysPlusEvent();
      //await this.getEventsGroupedByDay();
      await this.getAllArtists();
    } catch (error) {
      console.error("Fehler beim Laden der Daten:", error);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    allDays() {
      return this.days.map((x) => x.day);
    },
    allConferenceTypes() {
      let uniqueTypes = [];

      this.days.forEach((day) => {
        day.events.forEach((event) => {
          let type = event.event.conferenceType;
          if (type && uniqueTypes.findIndex((x) => x === type) === -1)
            uniqueTypes.push(type);
        });
      });
      return uniqueTypes;
    },
    allConferenceTopics() {
      let uniqueTopics = [];

      this.days.forEach((day) => {
        day.events.forEach((event) => {
          event.event.conferenceTopics.forEach((topic) => {
            if (uniqueTopics.findIndex((x) => x === topic) === -1)
              uniqueTopics.push(topic);
          });
        });
      });
      return uniqueTopics;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    filteredArtists() {
  if (!this.selectedGenres || this.selectedGenres.length === 0) {
    return this.allArtists;
  }
  return this.allArtists.filter((artist) => {
    return this.selectedGenres.some((genre) =>
      artist.genre.some((x) => x.id === genre)
    );
  });
    },

    allGenres() {
      let uniqueGenres = [];

      this.days.forEach((day) => {
        day.events.forEach((event) => {
          event.event.genres.forEach((genre) => {
            if (uniqueGenres.findIndex((x) => x.id === genre.id) === -1)
              uniqueGenres.push(genre);
          });
        });
      });

      this.allArtists.forEach((artist) => {
        artist.genre.forEach((genre) => {
          if (uniqueGenres.findIndex((x) => x.id === genre.id) === -1)
            uniqueGenres.push(genre);
        });
      });
      return uniqueGenres;
    },
    allVenues() {
      let uniqueVenues = new Set();
      this.days.forEach((day) => {
        day.events.forEach((event) => {
          if (event.event.venue) uniqueVenues.add(event.event.venue);
        });
      });
      return uniqueVenues;
    },
    filteredEventsGroupedByDay() {
      let result = JSON.parse(JSON.stringify(this.days));

      for (let day of result) {
        if (!this.selectedDays || this.selectedDays.length === 0 || this.selectedDays.includes(day.day)) {
          let filteredEvents = day.events;

          if (!this.conferenceOrFestival || this.conferenceOrFestival.length === 0) {
            // No filter applied, show all events
          } else {
            if (!this.conferenceOrFestival.includes("festival")) {
              filteredEvents = filteredEvents.filter((x) => x.event.conference);
            }
            if (!this.conferenceOrFestival.includes("conference")) {
              filteredEvents = filteredEvents.filter((x) => !x.event.conference);
            }
          }

          if (this.selectedGenres && this.selectedGenres.length > 0) {
            filteredEvents = filteredEvents.filter(
                (x) =>
                    x.event.genres.findIndex((g) => this.selectedGenres.includes(g.id)) !== -1 ||
                    x.event.genres.length === 0
            );
          }

          if (this.selectedCTopics && this.selectedCTopics.length > 0) {
            filteredEvents = filteredEvents.filter(
                (x) =>
                    x.event.conferenceTopics.findIndex((t) => this.selectedCTopics.includes(t)) !== -1 ||
                    x.event.conferenceTopics.length === 0
            );
          }

          if (this.selectedCTypes && this.selectedCTypes.length > 0) {
            filteredEvents = filteredEvents.filter(
                (x) =>
                    this.selectedCTypes.includes(x.event.conferenceType) ||
                    x.event.conferenceType == null
            );
          }

          day.events = filteredEvents;
        } else {
          day.events = [];
        }
      }

      return result.sort((a, b) => new Date(a.day) - new Date(b.day));
    }
  },
  methods: {
    async getAllDaysPlusEvent() {
      this.days = await getAllDaysWithEvents();
      this.$store.dispatch("events/setEvents",this.days)
    },
    async getAllArtists() {
      let response = await getAllVisibleArtists();
      this.allArtists = response;
    },
    weekday(day) {
      let moment = require("moment");
      return moment(day).format("dddd");
    },
    dateToday(date) {
      let moment = require("moment");
      return moment(date).format("DD[.]MM dddd");
    },
  },
};
</script>

<style scoped>
.filter-chip {
  padding: 1em 2em;
  opacity: 1;
}
.filter-chip-active {
  opacity: 1 !important;
}
.tab-active .filter-chip {
  opacity: 1;
}
</style>
