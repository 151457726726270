<template>
  <v-sheet
    min-height="150px"
    style="z-index: 1"
    color="grey-darken-4"
    class="rounded-xl pa-5 d-flex flex-wrap justify-space-between"
  >
    <v-row class="bg-primary custom-border-radius">
      <v-col
        style="position: relative"
        class="pa-5 px-4 custom-border-radius elevation-2 col-md-9 col-7"
      >
        <div
          class="font-weight-bold"
          style="font-size: 1.5rem"
          v-text="ticket.ownerName"
        ></div>
        <div class="text-truncate" v-text="'#' + ticket.uid"></div>
        <v-chip
          class="ma-2"
          style="position: absolute; bottom: 0; left: 0"
          v-text="ticket.year"
        ></v-chip>
      </v-col>

      <v-col
        style="position: relative"
        class="pa-2 px-4 custom-border-radius elevation-2 col-md-3 col-5 d-flex flex-column justify-center"
      >
        <div
          style="
            position: absolute;
            left: -2px;
            top: 50%;
            transform: translateY(-50%);
            height: 85%;
            width: 1px;
            border-left: 3px dotted grey;
          "
        ></div>
        <div
          class="font-weight-bold text-right"
          style="font-size: 1.5rem"
          v-text="ticket.price + '€'"
        ></div>
        <div class="text-right">incl. 19% VAT</div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "SingleTicket",
  props: {
    ticket: Object,
  },
};
</script>

<style scoped></style>
