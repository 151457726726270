<template>
  <ViewHeadline headline-text="My Feed" />
  <div style="max-width: 400px" class="mx-auto">
    <v-dialog max-width="600px" v-model="createFeedDialog">
      <template #activator="{ props }">
        <v-btn
          class="hidden-sm-and-down"
          icon
          size="x-large"
          elevation="5"
          fab
          color="white"
          style="position: fixed; right: 25px; bottom: 25px"
          v-bind="props"
          ><v-icon size="x-large">mdi-plus</v-icon></v-btn
        >
      </template>
      <CreateFeedItem @toggleDialog="toggleCreateFeedDialog" />
    </v-dialog>
    <MyFeed />
  </div>
</template>

<script>
import MyFeed from "@/components/feed/MyFeed";
import CreateFeedItem from "@/components/feed/CreateFeedItem";
import ViewHeadline from "@/components/helpers/ViewHeadline";
export default {
  name: "MyFeedView",
  components: { CreateFeedItem, MyFeed, ViewHeadline },
  data() {
    return {
      createFeedDialog: false,
    };
  },
  methods: {
    toggleCreateFeedDialog(value) {
      this.createFeedDialog = value;
    },
  },
};
</script>

<style scoped></style>
