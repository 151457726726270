

const notifications=[]
    const firebase=null
let endpoint=null
const initialState = {
    notifications,
    endpoint,
    firebase
}

export const notificationsStore = {
    namespaced: true,
    state: initialState,
    actions: {
        setFirebaseToken({commit}, token){
          commit("setFirebaseToken",token)
        },

        add({ commit }, notification) {
            commit("add",notification)
        },
        pushError({ commit }, error) {
            commit("add",{type:"error",text:error})
        },
        remove({ commit }, notification) {
            commit("remove",notification)
        },
        },

    mutations: {
        setFirebaseToken(state, token) {
        state.firebase=token;
        },
        add(state, notification){
            state.notifications.push(notification);
            setTimeout(() => {
                notification.animation=true;
                setTimeout(() => {state.notifications.pop()},2000)

                }, 7000);
        },



    }
};