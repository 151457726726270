<template>
  <ViewHeadline headline-text="Lobby" />
  <v-container style="position: relative" color="transparent" class="pa-7">
    <ContentLockedMessage :role="{ id: 0, name: 'ROLE_TICKET_24' }" />
    <v-chip-group filter v-model="filter.id" color="white">
      <v-chip
        filter
        style="border-radius: 10px"
        v-for="tag of tags"
        :value="tag.id"
        :key="tag.id"
        >{{ tag.name }}</v-chip
      >
    </v-chip-group>

    <pagination-wrapper
      :filter="filter"
      :sort="sort"
      @update:filter="
        (t) => {
          filter = t;
        }
      "
      url="user/get/lobby/page/"
    >
      <template #filter="{ filter, items }">
        <div class="d-flex justify-space-between align-center">
          <div style="font-size: 0.7rem" class="text-truncate">
            {{ items.length }}
            <span v-if="filter.search.length <= 0">Users</span>
            <span v-else>Users found</span>
          </div>
          <v-select
            item-title="text"
            return-object
            class="smaller-font px-0 flex-shrink-1"
            style="font-size: 0.7rem; max-width: 150px"
            density="compact"
            hide-details
            variant="text"
            v-model="sort"
            :items="sortItems"
            flat
          >
            <template v-slot:prepend-inner>
              <v-icon small>mdi-swap-vertical</v-icon>
            </template>
            <template v-slot:selection="{ item }">
              <span class="text-truncate">Sorted by: {{ item.text }}</span>
            </template>
          </v-select>
        </div>
        <v-text-field
          class="mb-2"
          hint="You can also filter by tags by clicking above."
          style="border-radius: 15px"
          v-model="filter.search"
          prepend-inner-icon="mdi-magnify"
          placeholder="Search by Name, Profession & Team"
          variant="outlined"
          density="compact"
        ></v-text-field>
      </template>
      <template #loading="{ loading }">
        <div class="wrapper" v-if="loading">
          <v-skeleton-loader
            color="transparent"
            style="
              width: 100%;
              border-radius: 25px;
              height: 100%;
              min-height: 200px;
              background-color: #e0e0e0;
              border: solid 1px black;
            "
            v-for="i in 15"
            :key="i"
          />
        </div>
      </template>
      <template #default="{ items, loading }">
        <p v-if="(items.length <= 0) & !loading">No users found</p>
        <div class="wrapper mb-8 mb-md-3" v-if="!loading">
          <UserSheetWall v-if="ownUser" :user="ownUser" />
          <UserSheetWall
            v-for="item of items"
            :key="item.id"
            @addTagToFilter="addTagToFilter"
            :index="item.id"
            :hover="false"
            :user="item"
          />
        </div>
      </template>
    </pagination-wrapper>
  </v-container>
</template>

<script>
import ApiService from "@/services/api.service";
import UserSheetWall from "@/views/Lobby/UserSheetWall";
import ContentLockedMessage from "@/views/ViewPermission/ContentLockedMessage";
import PaginationWrapper from "@/components/PaginationWrapper.vue";
import { getOwnUserForLobby } from "@/services/user.service";
import ViewHeadline from "@/components/helpers/ViewHeadline";
export default {
  name: "LobbyNew",
  components: {
    PaginationWrapper,
    ContentLockedMessage,
    UserSheetWall,
    ViewHeadline,
  },
  data() {
    return {
      sort: {
        text: "A-Z",
        value: {
          sortBy: "firstName",
          order: "ASC",
        },
      },
      sortItems: [
        {
          text: "A-Z",
          value: {
            sortBy: "firstName",
            order: "ASC",
          },
        },
        {
          text: "Z-A",
          value: {
            sortBy: "firstName",
            order: "DESC",
          },
        },
      ],
      loading: false,
      showFilter: false,
      sortedBy: null,
      users: [],
      filter: {
        search: "",
        id: null,
      },
      filterByTags: [],
      filterByName: "",
      filterByCompanies: [],
      filterByRoles: [],
      searchQuery: "",
      tags: [],
      ownUser: null,
    };
  },
  watch: {},
  created() {
    if (this.filter) {
      this.addTagToFilter(Number(this.filter));
    }
    this.getUsers();
    this.getAllTags();
    this.getOwnUserForLobby();
  },
  computed: {
    companies() {
      return Array.from(
        new Set(
          this.users.map((user) => {
            return user.companyName;
          })
        )
      );
    },
    roles() {
      return Array.from(
        new Set(
          this.users.map((user) => {
            return user.labelRole;
          })
        )
      );
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    async getOwnUserForLobby() {
      this.ownUser = await getOwnUserForLobby();
    },
    removeFromFilter(tagId) {
      this.filter.ids.splice(this.filter.ids.indexOf(tagId), 1);
    },
    displayTag(tagItem) {
      if (this.tags.length > 0) {
        let result = this.tags.find((tag) => {
          return tag.id === tagItem;
        });
        if (result) return result.name;
      }
      return null;
    },
    getAllTags() {
      let self = this;
      ApiService.get("tags/get/all").then((response) => {
        self.tags = response.data;
      });
    },
    addTagToFilter(id) {
      if (this.filterByTags.includes(id))
        this.filterByTags.splice(this.filterByTags.indexOf(id), 1);
      else this.filterByTags.push(id);
    },
    getUsers() {
      let self = this;
      self.loading = true;
      ApiService.get("user/get/lobby").then((response) => {
        self.users = response.data;
        for (let user of self.users) {
          user.bigDisplay = Math.random() < 0.1;
        }
        this.$store.dispatch("network/setUsers", self.users);
        self.loading = false;
        console.log("USERS: ", self.users);
      });
    },
    isPrime(n) {
      if (n <= 1) return false;
      for (var i = 2; i <= n - 1; i++) if (n % i == 0) return false;
      return true;
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

@media screen and (max-width: 600px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 0 10px;
  }

  .wrapper > *:nth-child(even) {
    transform: translateY(30px);
  }
}
.wrapper > div {
  width: 100%;
  height: 100%;
  min-height: 200px;
}

.background-name span {
  color: white !important;
}
</style>
