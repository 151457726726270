<template>
  <div v-if="event && !loading">
    <div
      style="position: relative"
      class="d-flex align-center"
    >
      <v-container min-height="50vh" class="d-flex flex-wrap flex-column justify-center align-start">
        <p class="ml-1"><span style="font-size:1.2rem;color:rgb(var(--v-theme-accentYellow))" v-text="type"></span></p>
        <h1
          style=""
          class="text-left text-md-h2"
        >
          {{ event.event.title }}
        </h1>
        <h2><span
            style="color: var(--v-accent-base); font-size: 1.2rem"
            class="text-uppercase text-truncate font-weight-thin mr-1"
            v-for="(genre, index) of event.event.genres"
            :key="genre.id"
        >{{ genre.name
          }}<span v-if="index < event.event.genres.length - 1">,</span>
            </span></h2>
        <p>
          <span>w\</span
          ><span
            class="ml-1"
            v-for="(artist, index) of event.artists"
            :key="artist.id"
        >{{ artist.name
          }}<span v-if="index < event.artists.length - 1">,</span></span
        >
        </p>
        <div class="d-flex ga-5 mt-5">
          <div
              class="d-flex justify-center align-center "
          >
            <v-icon>mdi-map-marker</v-icon
            ><span
              class="text-center"
              v-if="event.event.venue"
              v-text="event.event.venue.name"
          ></span
          ><span v-else>TBA</span>
          </div>
          <div
              class="d-flex justify-center align-center "
          >
            <v-icon>mdi-calendar</v-icon
            ><span class="text-center" v-text="date"></span>
          </div>
          <div
              class="d-flex justify-center align-center "
          >
            <v-icon>mdi-clock</v-icon
            ><span class="text-center" v-text="time"></span>
          </div>
        </div>
      </v-container>
    </div>
    <v-spacer style="height: 150px" />
    <v-container class="pb-10">
      <div v-text="event.event.description"></div>
      <div class="d-flex justify-space-between align-center">
        <div>
          <v-btn class="ml-2" icon
            ><v-icon size="x-large">mdi-instagram</v-icon></v-btn
          >
          <v-btn class="ml-2" icon
            ><v-icon size="x-large">mdi-facebook</v-icon></v-btn
          >
        </div>
        <v-btn
          large
          append-icon="mdi-arrow-top-right"
          color="accentYellow"
          v-if="event.event.ticketLink"
          :href="event.event.ticketLink"
          target="_blank"
          >Buy tickets</v-btn
        >
      </div>
    </v-container>
    <v-container>
      <h3 class="mt-5">Speakers & Artists on this:</h3>
      <ArtistListPublic :artists="event.artists" />
    </v-container>
  </div>
  <div v-if="loading" class="skeleton-wrapper">
    <div class="">
      <v-container min-height="50vh" class="d-flex flex-wrap flex-column justify-center align-start">
        <v-skeleton-loader
            type="text"
            color="transparent"
            class="mt-5 mb-5"
            width="30%"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="heading"
          color="transparent"
          class="mt-5 mb-5"
          width="70%"
        ></v-skeleton-loader>
        <v-skeleton-loader
            type="text"
            color="transparent"
            class="mt-5 mb-5"
            width="30%"
        ></v-skeleton-loader>
        <v-skeleton-loader
            type="text"
            color="transparent"
            class="mt-5 mb-5"
            width="30%"
        ></v-skeleton-loader>
        <v-skeleton-loader
            type="text"
            color="transparent"
            class="mt-5 mb-5"
            width="30%"
        ></v-skeleton-loader>
      </v-container>
    </div>
    <v-spacer style="height: 200px" />
    <v-container class="pb-10">
      <v-skeleton-loader
        type="paragraph"
        class="mt-5"
        color="transparent"
      ></v-skeleton-loader>
      <div class="d-flex justify-space-between align-center mt-5">
        <v-skeleton-loader
          type="button"
          width="100px"
          color="transparent"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="button"
          width="150px"
          color="transparent"
        ></v-skeleton-loader>
      </div>
    </v-container>
    <v-container>
      <v-skeleton-loader
        type="heading"
        class="mb-5"
        width="30%"
        color="transparent"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="heading"
        class="mb-5"
        width="40%"
        color="transparent"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="card-avatar, actions"
        class="mb-5"
        color="transparent"
      ></v-skeleton-loader>
    </v-container>
  </div>
</template>

<script>
import { getEventWithArtistsById } from "@/services/event.service";
import ArtistListPublic from "@/components/artist/ArtistListPublic";

export default {
  name: "SingleEventSite",
  components: { ArtistListPublic },
  props: {
    eventId: String,
  },

  computed: {
    type(){
      if(this.event.event.conference) return "Conference";
      else return "Festival";
    },
    date() {
      let moment = require("moment");
      return moment(this.event.event.dateTime).format("dddd MM[/]DD[/]YY");
    },
    time() {
      let moment = require("moment");
      return moment(this.event.event.dateTime).format("LT");
    },
  },
  data() {
    return {
      event: null,
      loading: false,
    };
  },
  created() {
    if (this.eventId) this.getEvent();
  },
  methods: {
    getEvent: async function () {
      this.loading = true;
      this.event = await getEventWithArtistsById(this.eventId);
      this.loading = false;
      console.log(this.event);
    },
  },
};
</script>

<style scoped></style>
